import './App.css';
import { useState, useEffect, useRef } from 'react';

function Carousel({style,variables}) {
    const carouselRef = useRef(null);
    const [carouselWidth, setCarouselWidth] = useState(0);
    const [contentWidth, setContentWidth] = useState(0);
    // Tworzenie tablicy numerów obrazów
    const imageNumbers = Array.from({ length: 25 }, (_, i) => i + 1);
  
    useEffect(() => {
      if (carouselRef.current) {
        const updateSizes = () => {
          setCarouselWidth(carouselRef.current.offsetWidth);
          setContentWidth(carouselRef.current.scrollWidth);
        };
  
        updateSizes();
  
        window.addEventListener('resize', updateSizes);
        return () => window.removeEventListener('resize', updateSizes);
      }
    }, []);

    useEffect(() => {
        const carousel = carouselRef.current;
        if (!carousel) return;
    
        const scrollStep = 2;
        const scrollSpeed = 30;
    
        const autoScroll = setInterval(() => {
          if (carousel.scrollLeft + carouselWidth >= contentWidth - 1) {
            carousel.scrollLeft = 0;
          } else {
            carousel.scrollLeft += scrollStep;
          }
        }, scrollSpeed);
    
        return () => clearInterval(autoScroll);
      }, [carouselWidth, contentWidth]);    
    
    // Funkcja do mieszania tablicy
    function shuffleArray(array) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
    }

    // Mieszanie tablicy numerów obrazów przed renderowaniem
    shuffleArray(imageNumbers);
  
    return (
      <div>
        <div style={{background: style.background, color: style.color}} className='c-title'>{variables.available}</div>
        <a href={variables.link}>
          <div className="carousel" style={{background: style.background}} ref={carouselRef}>
          {imageNumbers.map((number) => (
            <div className='photo' key={number}>
              <div className='online'>🟢</div>
              <div className='c-block'>
                <div className='block'>{variables.block}</div>
              </div>
              <img style={{background: style.background}} src={`img/women/${number}.jpg`} alt={`Women ${number}`} />
            </div>
          ))}
          </div>
        </a>
      </div>
    );
}

export default Carousel;
