import './App.css';
import { getLanguage } from './Language';
import MediaQuery from 'react-responsive';
import { useState, useEffect } from 'react';
import ReactGA from "react-ga4";
import Carousel from './Carousel';

const TRACKING_ID = "G-13WXXZ2FC8"; //Google Analytics
const icon = ['hearth1', 'hearth2', 'lips1', 'lips2', 'lips3', 'sex1', 'tinder', 'sex2'];
const style = [
  {background: '#ffffffb5', color: 'black', mask: '#e1e1e1'},
  {background: '#000000c2', color: '#f1f1f1', mask: '#0b0b0b'}
];

const randomNumber = Math.floor(Math.random() * 15) + 1;
const randomStyle = Math.floor(Math.random() * style.length);
const randomIcon = Math.floor(Math.random() * icon.length);

function App() {
  const [country, setCountry] = useState(null);
  //Ustawianie języka dla wybranego kraju
  const [variables, setVariables] = useState({});

  // Wywołuje zapytanie do API geolokalizacji IP przy pierwszym renderowaniu komponentu, aktualizując stan kraju na podstawie odpowiedzi.
  useEffect(() => {
    fetch('https://ipapi.co/json/')
      .then(response => response.json())
      .then(data => {
        setCountry(data.country_name);
      })
      .catch(error => console.error('Error fetching IP geolocation data:', error));
  }, []);

  // Wywołuje funkcję getLanguage z aktualną wartością country za każdym razem, gdy wartość country ulegnie zmianie.
  useEffect(() => {
    if (country) {
      getLanguage(country, setVariables);
    }
  }, [country]);

  // Aktualizuje tło strony w zależności od szerokości okna, reagując na zmianę rozmiaru okna i zmiany randomNumber.
  useEffect(() => {
    const updateBackground = () => {
      const bgType = window.innerWidth >= 1225 ? 'bg' : 'bg-mobile';
      document.body.style.backgroundImage = `url(img/${bgType}/bg${randomNumber}.${bgType === 'bg' ? 'jpg' : 'png'})`;
    };
  
    window.addEventListener('resize', updateBackground);
    updateBackground();
  
    return () => window.removeEventListener('resize', updateBackground);
  }, [randomNumber]);

  // Aktualizacja tytułu strony
  useEffect(() => {
    if (!country || !variables.line) {
      document.title = ''; // Możesz ustawić tutaj inny tytuł tymczasowy
    } else {
      document.title = variables.line;
      ReactGA.initialize(TRACKING_ID);
      console.log('analytics works!');
    }
  }, [country, variables.line]);

  return (
    <>
      <MediaQuery minWidth={1225}>
        <Content variables={variables} style={style[randomStyle]} />
      </MediaQuery>
      <MediaQuery maxWidth={1224}>
        <Content variables={variables} style={style[randomStyle]} />
      </MediaQuery>
    </>
  );
}

const Content = ({ variables, style }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  // Opóźnienie 3 sekundy dla ładowania
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoaded(true);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  // Włączanie wyłączanie możliwości używania 'scrolla'
  useEffect(() => {
    if (!isLoaded) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }

    // Opcjonalnie, czyszczenie na odmontowanie komponentu
    return () => document.body.classList.remove('no-scroll');
  }, [isLoaded]);

  return (
    <div>
      <div className={`mask ${isLoaded ? 'hidden' : 'visible'}`} style={{backgroundColor: style.mask}}></div>
      {!isLoaded && (
        <div className='loading'>
          <div className='icon'><img src={'icon/'+icon[randomIcon]+'.png'}/></div>
        </div>
      )}
      <div className={`${isLoaded ? 'visible' : 'hidden'}`}>
        <div className='top'>
          <div className='c-top'>
            <a href={variables.link}><div className='t-btn-b'>{variables.login}</div></a>
            <a href={variables.link}><div className='t-btn-r'>{variables.register}</div></a>
          </div>
        </div>
        <div className='container' style={{ backgroundColor: style.background, color: style.color }}>
          <div className='title'>{variables.line}</div>
          <div className='subtitle'>{variables.message}</div>
          <a href={variables.link}>
            <div className='btn' onClick={() => ReactGA.event({ category: 'Button', action: 'Click', label: 'Confirmation of age' })}>
              {variables.confirmation}
            </div>
          </a>
        </div>
        <Carousel style={style} variables={variables}/>
        <div className='description' style={{background: style.background, color: style.color}}>
          <img src='icon/check.png'/>
          <div className='title desc'>{variables.registrationDetails}</div>
          <div className='subtitle desc'>{variables.registrationForm}</div>
          <img src='icon/secure.png'/>
          <div className='title desc'>{variables.discretionMessage}</div>
          <div className='subtitle desc'>{variables.securityMessage}</div>
          <img src='icon/location.png'/>
          <div className='title desc'>{variables.womenMessage}</div>
          <div className='subtitle desc'>{variables.womenCount}</div>
        </div>
        <div style={{ backgroundColor: style.background}} className='footer'>
          <div className='f-container'>
            <div className='f-m'><a href={variables.link}>{variables.login}</a></div>
            <div className='f-m'><a href={variables.link}>{variables.register}</a></div>
            <div className='f-m'><a href={variables.link}>{variables.sexCam}</a></div>
            <div className='f-m'><a href={variables.link}>{variables.sexDate}</a></div>
          </div>
          <div className='f-container' style={{padding: '2em 1em', color: style.color}}>{variables.footer}</div>
        </div>
      </div>
    </div>
  );
};

export default App;
