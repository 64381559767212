export const getLanguage = (country, setVariables) => {
  const otherCountries = 'https://date-till-late.us/tds/ae?tdsId=s5428sto_r&tds_campaign=s5428sto&s1=ps&utm_source=int&utm_sub=opnfnl&affid=fe6a20bd&subid2={subid}&subid=NOWA-ROKSA&clickid={clickid}'; //Smartlink VIII
  const cPoland = '	https://redirecting8.eu/p/eVlP/4J8d/KL6s'; //Smartlink VI
  const cGermany = otherCountries; //Smartlink VI
  const cSweden = otherCountries; //Smartlink VI
  const cNorway = otherCountries;
  const cUsa = otherCountries; //Smartlink VI
  const cEnglish = otherCountries; //Smartlink VI
  const cItaly = otherCountries; //Smartlink VI
  const cSpain = otherCountries; 
  const cMexico = otherCountries;
  const cEgypt = otherCountries;

  switch (country) {
    case 'Poland':
      setVariables({
        footer: "Portal zawiera ogłoszenia dodawane przez prawdziwe osoby zamieszkałe na terenie Polski. Udostępnianie na zewnątrz jakichkolwiek zdjęć i nagrań znajdujących się na portalu jest zabronione!",
        available: "Zaloguj się, aby odkrywać nowe profile",
        login: "Zaloguj się",
        register: "Zarejestruj się",
        sexCam: "Seks Kamerki",
        sexDate: "Seks spotkania",
        block: "Zaloguj się, aby wyświetlić profil",
        line: "Nie masz dostępu!",
        message: "Niestety nie masz dostępu do tego profilu. Kliknij przycisk poniżej i potwierdź, że jesteś pełnoletni. Po kliknięciu zostaniesz automatycznie przekierowany na portal randkowy.",
        confirmation: "Tak, jestem pełnoletni! (+18)",
        registrationDetails: "Całkowicie darmowa i łatwa rejestracja",
        registrationForm: "Prosty i przejrzysty formularz rejestracyjny konta. Wystarczy potwierdzić swój adres email i pełny dostęp do twojego konta zostaje odblokowany!",
        discretionMessage: "Pełna dyskrecja i anonimowość",
        securityMessage: "Gwarantujemy pełną dyskrecję i anonimowość. Zapewniamy wysokie bezpieczeństwo podczas korzystania z portalu. Pisz, rozmawiaj i umawiaj się na randki BEZPIECZNIE!",
        womenMessage: "Szerokie grono kobiet",
        womenCount: "Nie musisz martwić się o randki! Ponad 1000+ dostępnych i samotnych kobiet w twoim regionie.",
        link: cPoland
      });
      break;

    case 'Norway':
      setVariables({
        footer: "Nettstedet inneholder annonser lagt inn av ekte personer som bor i Polen. Det er forbudt å dele noen bilder og opptak fra nettstedet eksternt!",
        available: "Logg inn for å utforske nye profiler",
        login: "Logg inn",
        register: "Registrer deg",
        sexCam: "Sex Kameraer",
        sexDate: "Sex Møter",
        block: "Logg inn for å vise profilen",
        line: "Du har ikke tilgang!",
        message: "Beklager, du har ikke tilgang til denne profilen. Klikk på knappen nedenfor og bekreft at du er myndig. Etter å ha klikket, vil du automatisk bli omdirigert til en datingside.",
        confirmation: "Ja, jeg er myndig! (+18)",
        registrationDetails: "Helt gratis og enkel registrering",
        registrationForm: "En enkel og oversiktlig registreringsskjema for kontoen. Bare bekreft e-postadressen din, og full tilgang til kontoen din blir låst opp!",
        discretionMessage: "Full diskresjon og anonymitet",
        securityMessage: "Vi garanterer full diskresjon og anonymitet. Vi sikrer høy sikkerhet når du bruker portalen. Skriv, snakk og avtal datoer SIKKERT!",
        womenMessage: "Et bredt spekter av kvinner",
        womenCount: "Du trenger ikke å bekymre deg for datoer! Over 1000+ tilgjengelige og enslige kvinner i din region.",
        link: cNorway
      });
      break;

    case 'United States':
      setVariables({
        footer: "The portal contains advertisements added by real people living in Poland. Sharing any photos and recordings from the portal outside is prohibited!",
        available: "Log in to discover new profiles",
        login: "Log in",
        register: "Register",
        sexCam: "Sex Cams",
        sexDate: "Sex Meetings",
        block: "Log in to view the profile",
        line: "You do not have access!",
        message: "Unfortunately, you do not have access to this profile. Click the button below and confirm that you are of legal age. After clicking, you will be automatically redirected to the dating portal.",
        confirmation: "Yes, I am of legal age! (+18)",
        registrationDetails: "Completely free and easy registration",
        registrationForm: "A simple and clear account registration form. Just confirm your email address and full access to your account is unlocked!",
        discretionMessage: "Complete discretion and anonymity",
        securityMessage: "We guarantee complete discretion and anonymity. We ensure high security while using the portal. Write, chat, and arrange dates SAFELY!",
        womenMessage: "A wide circle of women",
        womenCount: "You don't have to worry about dates! Over 1000+ available and single women in your area.",
        link: cUsa
      });
      break;
      
    case 'United Kingdom':
    case 'Canada':
    case 'Australia':
      setVariables({
        footer: "The portal contains advertisements added by real people living in Poland. Sharing any photos and recordings from the portal outside is prohibited!",
        available: "Log in to discover new profiles",
        login: "Log in",
        register: "Register",
        sexCam: "Sex Cams",
        sexDate: "Sex Meetings",
        block: "Log in to view the profile",
        line: "You do not have access! (+18)",
        message: "Unfortunately, you do not have access to this profile. Click the button below and confirm that you are of legal age. After clicking, you will be automatically redirected to the dating portal.",
        confirmation: "Yes, I am of legal age",
        registrationDetails: "Completely free and easy registration",
        registrationForm: "A simple and clear account registration form. Just confirm your email address and full access to your account is unlocked!",
        discretionMessage: "Complete discretion and anonymity",
        securityMessage: "We guarantee complete discretion and anonymity. We ensure high security while using the portal. Write, chat, and arrange dates SAFELY!",
        womenMessage: "A wide circle of women",
        womenCount: "You don't have to worry about dates! Over 1000+ available and single women in your area.",
        link: cEnglish
      });
      break;

    case 'Germany':
      setVariables({
        footer: "Das Portal enthält Anzeigen von echten Personen, die in Polen leben. Das Teilen von Bildern und Aufnahmen des Portals nach außen ist verboten!",
        available: "Melde dich an, um neue Profile zu entdecken",
        login: "Anmelden",
        register: "Registrieren",
        sexCam: "Sex Cams",
        sexDate: "Sex Treffen",
        block: "Loggen Sie sich ein, um das Profil anzuzeigen",
        line: "Sie haben keinen Zugang!",
        message: "Leider haben Sie keinen Zugang zu diesem Profil. Klicken Sie auf den untenstehenden Button und bestätigen Sie, dass Sie volljährig sind. Nach dem Klicken werden Sie automatisch zum Dating-Portal weitergeleitet.",
        confirmation: "Ja, ich bin volljährig (+18)",
        registrationDetails: "Vollständig kostenlose und einfache Registrierung",
        registrationForm: "Ein einfaches und klares Anmeldeformular für das Konto. Bestätigen Sie einfach Ihre E-Mail-Adresse und der vollständige Zugriff auf Ihr Konto wird freigeschaltet!",
        discretionMessage: "Vollständige Diskretion und Anonymität",
        securityMessage: "Wir garantieren vollständige Diskretion und Anonymität. Wir sorgen für hohe Sicherheit bei der Nutzung des Portals. Schreiben, chatten und sicher daten!",
        womenMessage: "Ein großer Kreis von Frauen",
        womenCount: "Sie müssen sich keine Sorgen um Dates machen! Über 1000+ verfügbare und alleinstehende Frauen in Ihrer Gegend.",
        link: cGermany
      });
      break;

    case 'Sweden':
      setVariables({
        footer: "Webbplatsen innehåller annonser från verkliga personer som bor i Polen. Det är förbjudet att dela några bilder och inspelningar från webbplatsen externt!",
        available: "Logga in för att upptäcka nya profiler",
        login: "Logga in",
        register: "Registrera dig",
        sexCam: "Sexkameror",
        sexDate: "Sexmöten",
        block: "Logga in för att visa profilen",
        line: "Du har inte åtkomst!",
        message: "Tyvärr har du inte åtkomst till denna profil. Klicka på knappen nedan och bekräfta att du är myndig. Efter att ha klickat kommer du automatiskt att omdirigeras till dejtingsportalen.",
        confirmation: "Ja, jag är myndig! (+18)",
        registrationDetails: "Helt gratis och enkel registrering",
        registrationForm: "Ett enkelt och överskådligt konto-registreringsformulär. Bekräfta bara din e-postadress och full åtkomst till ditt konto blir upplåst!",
        discretionMessage: "Full diskretion och anonymitet",
        securityMessage: "Vi garanterar full diskretion och anonymitet. Vi säkerställer hög säkerhet vid användning av portalen. Skriv, chatta och ordna träffar SÄKERT!",
        womenMessage: "Ett brett utbud av kvinnor",
        womenCount: "Du behöver inte oroa dig för dejter! Över 1000+ tillgängliga och singelkvinnor i ditt område.",
        link: cSweden
      });
      break;

    case 'Italy':
      setVariables({
        footer: "Il portale contiene annunci inseriti da persone reali che vivono in Polonia. È vietato condividere all'esterno qualsiasi foto e registrazione presente sul portale!",
        available: "Accedi per scoprire nuovi profili",
        login: "Accedi",
        register: "Registrati",
        sexCam: "Sex Cam",
        sexDate: "Incontri sessuali",
        block: "Accedi per visualizzare il profilo",
        line: "Non hai accesso!",
        message: "Purtroppo non hai accesso a questo profilo. Clicca il pulsante sottostante e conferma di essere maggiorenne. Dopo aver cliccato verrai reindirizzato automaticamente al portale di incontri.",
        confirmation: "Sì, sono maggiorenne! (+18)",
        registrationDetails: "Registrazione completamente gratuita e facile",
        registrationForm: "Un modulo di registrazione dell'account semplice e chiaro. Basta confermare il tuo indirizzo email e l'accesso completo al tuo account viene sbloccato!",
        discretionMessage: "Discrezione e anonimato completi",
        securityMessage: "Garantiamo completa discrezione e anonimato. Assicuriamo un'elevata sicurezza durante l'utilizzo del portale. Scrivi, chatta e organizza appuntamenti in SICUREZZA!",
        womenMessage: "Un vasto cerchio di donne",
        womenCount: "Non devi preoccuparti degli appuntamenti! Oltre 1000+ donne disponibili e single nella tua zona.",
        link: cItaly
      });
      break;

    case 'Spain':
    case 'Mexico':
      setVariables({
        footer: "El portal contiene anuncios agregados por personas reales que residen en Polonia. ¡Está prohibido compartir cualquier foto y grabación del portal en el exterior!",
        available: "Inicia sesión para descubrir nuevos perfiles",
        login: "Iniciar sesión",
        register: "Registrarse",
        sexCam: "Cámaras de Sexo",
        sexDate: "Encuentros Sexuales",
        block: "Inicia sesión para ver el perfil",
        line: "¡No tienes acceso!",
        message: "Lamentablemente, no tienes acceso a este perfil. Haz clic en el botón de abajo y confirma que eres mayor de edad. Una vez que hagas clic, serás redirigido automáticamente a un portal de citas.",
        confirmation: "Sí, soy mayor de edad! (+18)",
        registrationDetails: "Registro totalmente gratuito y fácil",
        registrationForm: "Formulario de registro sencillo y claro. ¡Solo confirma tu dirección de correo electrónico y tendrás acceso completo a tu cuenta!",
        discretionMessage: "Total discreción y anonimato",
        securityMessage: "Garantizamos total discreción y anonimato. Aseguramos alta seguridad mientras usas el portal. ¡Escribe, chatea y organiza citas con SEGURIDAD!",
        womenMessage: "Amplia selección de mujeres",
        womenCount: "¡No te preocupes por las citas! Más de 1000+ mujeres disponibles y solteras en tu región.",
        link: cSpain
      });
      break;

    default:
      setVariables({
        footer: "The portal contains advertisements added by real people living in Poland. Sharing any photos and recordings from the portal outside is prohibited!",
        available: "Log in to discover new profiles",
        login: "Log in",
        register: "Register",
        sexCam: "Sex Cams",
        sexDate: "Sex Meetings",
        block: "Log in to view the profile",
        line: "You do not have access! (+18)",
        message: "Unfortunately, you do not have access to this profile. Click the button below and confirm that you are of legal age. After clicking, you will be automatically redirected to the dating portal.",
        confirmation: "Yes, I am of legal age",
        registrationDetails: "Completely free and easy registration",
        registrationForm: "A simple and clear account registration form. Just confirm your email address and full access to your account is unlocked!",
        discretionMessage: "Complete discretion and anonymity",
        securityMessage: "We guarantee complete discretion and anonymity. We ensure high security while using the portal. Write, chat, and arrange dates SAFELY!",
        womenMessage: "A wide circle of women",
        womenCount: "You don't have to worry about dates! Over 1000+ available and single women in your area.",
        // link: "https://date-till-late.us/tds/ae?tdsId=s5428sto_r&tds_campaign=s5428sto&s1=ps&utm_source=int&utm_sub=opnfnl&affid=fe6a20bd&subid2={subid}&subid=OTHERS&clickid={clickid}" //AdsEmpire
        link: otherCountries
      });
      break;
  }
};